export function pluralize(count, noun, plural) {
	return `${count} ${count !== 1 ? plural : noun}`;
}

export function arrayToString(array, separator = ', ') {
	return array.join(separator);
}

export function millisecondsToTimeFormat(duration, format = 'hh:mm:ss.ms') {
	let milliseconds = parseInt(duration % 1000),
		seconds = parseInt((duration / 1000) % 60),
		minutes = parseInt((duration / (1000 * 60)) % 60),
		hours = parseInt((duration / (1000 * 60 * 60)) % 24);

	let map = {
		hh: hours.toString().padStart(2, '0'),
		mm: minutes.toString().padStart(2, '0'),
		ss: seconds.toString().padStart(2, '0'),
		ms: milliseconds.toString().padStart(3, '0')
	};

	const str = format.replace(/hh|mm|ss|ms/gi, function (matched) {
		return map[matched];
	});

	return str;
}

export function moveArray(arr, fromIndex, toIndex) {
	const elem = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, elem);

	return arr;
}

export const monthNow = () => new Date().getMonth('en-GB') + 1;

export function getMonthName(monthNumber) {
	const date = new Date();
	date.setMonth(monthNumber - 1);

	return date.toLocaleString('en-GB', {
		month: 'long'
	});
}

export const convertObjType = (str) => str.split('_').join(' ');

export function findContinent(continents, country) {
	const { continent } = continents[country];

	return continent;
}
export const blogOptions =
	'undo redo | blocks fontsize | ' +
	'bold italic backcolor | alignleft aligncenter ' +
	'alignright alignjustify | bullist numlist outdent indent | ' +
	'link image media removeformat | help';

export const defaultOptions =
	'undo redo | blocks fontsize | ' +
	'bold italic backcolor | alignleft aligncenter ' +
	'alignright alignjustify | bullist numlist outdent indent | ' +
	'removeformat | help';

export const insertAdsEveryNItems = (data, ads, n) => {
	if(ads.length === 0) return data;
	
    const dataWithAds = [];
    const adCount = Math.ceil(data.length / n);

    const shuffledAds = ads.sort(() => Math.random() - 0.5);

    let adIndex = 0;

    for (let i = 0; i < data.length; i++) {
      dataWithAds.push(data[i]);

      if ((i + 1) % n === 0 && adIndex < adCount) {

        const ad = shuffledAds[adIndex % shuffledAds.length];
	
        dataWithAds.push({ ...ad, isAd: true });
        adIndex++;
      }
    }

    return dataWithAds;
}

export function maxDate(){
    const today = new Date()
    let dd = String(today.getDate()).padStart(2, 0)
    let mm = String(today.getMonth() + 1).padStart(2, 0)
    let yyyy = today.getFullYear() - 16;

    return `${yyyy}-${mm}-${dd}`;
}
